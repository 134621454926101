import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { requestOTP } from '../../services/api';
import OTPVerification from './OTPVerification';

function Register({ setIsAuthenticated }) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    try {
      await requestOTP(phoneNumber);
      setShowOTP(true);
    } catch (error) {
      console.error('Registration failed:', error);
      setError('Failed to send OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (showOTP) {
    return <OTPVerification phoneNumber={phoneNumber} setIsAuthenticated={setIsAuthenticated} />;
  }

  return (
    <Flex align="center" justify="center" height="100vh" bg="gray.50">
      <Box bg="white" p={8} rounded="md" shadow="md" w="sm">
        <Heading mb={6} textAlign="center">
          Sign Up
        </Heading>
        {error && (
          <Text color="red.500" textAlign="center" mb={4}>
            {error}
          </Text>
        )}
        <form onSubmit={handleSubmit}>
          <InputGroup mb={4}>
            <InputLeftAddon children="+1" />
            <Input
              type="tel"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
          </InputGroup>
          <Button
            colorScheme="teal"
            type="submit"
            width="full"
            isLoading={loading}
          >
            Get OTP
          </Button>
        </form>
      </Box>
    </Flex>
  );
}

export default Register;