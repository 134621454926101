import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AudioRecorder } from 'react-audio-voice-recorder';

const AudioRecorderWrapper = ({ onRecordingComplete, disabled }) => {
  const [isSafari, setIsSafari] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    setIsSafari(isSafariBrowser);
  }, []);

  const handleRecordingComplete = async (blob) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }
      
      console.log('Recording:', {
        type: blob.type,
        size: blob.size,
        browser: isSafari ? 'Safari' : 'Other'
      });
      
      if (blob.size === 0) {
        throw new Error('Empty audio recording');
      }
      
      const formData = new FormData();
      formData.append('audio', blob);
      await onRecordingComplete(formData);
    } catch (error) {
      console.error('Recording error:', error);
      toast({
        title: 'Recording Error',
        description: error.message || 'Failed to process recording',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <AudioRecorder 
        onRecordingComplete={handleRecordingComplete}
        disabled={disabled}
        audioTrackConstraints={{
          sampleRate: 16000,
          channelCount: 1,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }}
        mediaRecorderOptions={{
          audioBitsPerSecond: 128000,
          // Use MP3 for Safari, WAV for others (Gemini supports both)
          mimeType: isSafari ? 'audio/mp3' : 'audio/wav'
        }}
        showVisualizer={true}
      />
    </Box>
  );
};

export default AudioRecorderWrapper;