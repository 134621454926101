import React, { useState, useEffect } from 'react';
import {
  Box, Container, Heading, VStack, HStack, Text, Input, Button,
  Card, CardBody, CardFooter, useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  getRecipes,
  deleteRecipe,
  addToMealPlan,
  extractRecipeFromURL,
  getMealPlans,
  deleteMealPlan,
  syncGroceryList,
  createRecipe,
  updateRecipe,
} from '../../services/api';
import RecipeForm from './RecipeForm';

function Recipes() {
  const [recipes, setRecipes] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recipeURL, setRecipeURL] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRecipeId, setSelectedRecipeId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editingRecipe, setEditingRecipe] = useState(null);

  useEffect(() => {
    fetchRecipes();
  }, []);

  const fetchRecipes = async () => {
    try {
      const response = await getRecipes();
      setRecipes(response.data);
    } catch (error) {
      console.error('Error fetching recipes:', error);
      toast({
        title: 'Error fetching recipes',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteRecipe = async (id) => {
    try {
      await deleteRecipe(id);
      fetchRecipes();
      toast({
        title: 'Recipe deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting recipe:', error);
      toast({
        title: 'Error deleting recipe',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddToMealPlanClick = (id) => {
    setSelectedRecipeId(id);
    onOpen();
  };

  const handleAddToMealPlan = async () => {
    try {
      const response = await addToMealPlan({
        recipe: selectedRecipeId,
        date: selectedDate
      });
      
      if (response.status === 201 || response.data) {
        toast({
          title: 'Added to meal plan',
          description: `Recipe added to meal plan for ${selectedDate}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      }
    } catch (error) {
      console.error('Error adding to meal plan:', error);
      toast({
        title: 'Error adding to meal plan',
        description: error.response?.data?.error || 'Failed to add recipe to meal plan',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleExtractRecipe = async () => {
    setLoading(true);
    try {
      await extractRecipeFromURL(recipeURL);
      setRecipeURL('');
      fetchRecipes();
      toast({
        title: 'Recipe imported successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error extracting recipe:', error);
      toast({
        title: 'Error importing recipe',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCreateRecipe = async (recipeData) => {
    try {
      await createRecipe(recipeData);
      fetchRecipes();
      setIsFormOpen(false);
      toast({
        title: 'Recipe created',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error creating recipe',
        description: error.response?.data?.error || 'Failed to create recipe',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditRecipe = async (recipeData) => {
    try {
      await updateRecipe(editingRecipe.id, recipeData);
      fetchRecipes();
      setEditingRecipe(null);
      toast({
        title: 'Recipe updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error updating recipe',
        description: error.response?.data?.error || 'Failed to update recipe',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSearch = async (query) => {
    setSearchQuery(query);
    try {
      const response = await getRecipes({ params: { query } });
      setRecipes(response.data);
    } catch (error) {
      console.error('Error searching recipes:', error);
    }
  };

  const filteredRecipes = recipes.filter((recipe) => {
    const query = searchQuery.toLowerCase();
    const nameMatch = recipe.name.toLowerCase().includes(query);
    const instructionsMatch = recipe.instructions.toLowerCase().includes(query);
    const ingredientsMatch = recipe.ingredients.some((ingredient) =>
      ingredient.ingredient_name.toLowerCase().includes(query)
    );
    return nameMatch || instructionsMatch || ingredientsMatch;
  });

  return (
    <Container maxW="container.xl" py={8}>
      <HStack justify="space-between" mb={6}>
        <Heading>Recipes</Heading>
        <Button colorScheme="teal" onClick={() => setIsFormOpen(true)}>
          Create New Recipe
        </Button>
      </HStack>
      <VStack spacing={4} align="stretch">
        <Input
          placeholder="Recipe URL"
          value={recipeURL}
          onChange={(e) => setRecipeURL(e.target.value)}
        />
        <Button
          colorScheme="teal"
          onClick={handleExtractRecipe}
          isLoading={loading}
        >
          Import Recipe
        </Button>
        <Input
          placeholder="Search Recipes"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {(isFormOpen || editingRecipe) && (
          <Modal isOpen={true} onClose={() => {
            setIsFormOpen(false);
            setEditingRecipe(null);
          }} size="xl">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {editingRecipe ? 'Edit Recipe' : 'Create New Recipe'}
              </ModalHeader>
              <ModalBody>
                <RecipeForm
                  initialData={editingRecipe}
                  onSubmit={editingRecipe ? handleEditRecipe : handleCreateRecipe}
                  onCancel={() => {
                    setIsFormOpen(false);
                    setEditingRecipe(null);
                  }}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
        {filteredRecipes.map((recipe) => (
          <Card key={recipe.id}>
            <CardBody>
              <Heading size="md">{recipe.name}</Heading>
              <Text>{recipe.instructions}</Text>
            </CardBody>
            <CardFooter>
              <HStack spacing={2}>
                <Button onClick={() => navigate(`/recipes/${recipe.id}`)}>
                  View Details
                </Button>
                <Button onClick={() => setEditingRecipe(recipe)}>
                  Edit
                </Button>
                <Button onClick={() => handleDeleteRecipe(recipe.id)}>
                  Delete
                </Button>
                <Button onClick={() => handleAddToMealPlanClick(recipe.id)}>
                  Add to Meal Plan
                </Button>
              </HStack>
            </CardFooter>
          </Card>
        ))}
        <Button colorScheme="teal" onClick={fetchRecipes}>
          Refresh Recipes
        </Button>
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add to Meal Plan</ModalHeader>
          <ModalBody>
            <Input
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleAddToMealPlan}>
              Add to Plan
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
}

export default Recipes;