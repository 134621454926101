import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  IconButton,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';

const RecipeForm = ({ initialData, onSubmit, onCancel }) => {
  const [recipe, setRecipe] = useState(initialData || {
    name: '',
    instructions: '',
    ingredients: [{ ingredient_name: '', quantity: '', unit: '' }]
  });

  const toast = useToast();

  const handleIngredientChange = (index, field, value) => {
    const newIngredients = [...recipe.ingredients];
    newIngredients[index] = {
      ...newIngredients[index],
      [field]: value
    };
    setRecipe({ ...recipe, ingredients: newIngredients });
  };

  const addIngredient = () => {
    setRecipe({
      ...recipe,
      ingredients: [...recipe.ingredients, { ingredient_name: '', quantity: '', unit: '' }]
    });
  };

  const removeIngredient = (index) => {
    const newIngredients = recipe.ingredients.filter((_, i) => i !== index);
    setRecipe({ ...recipe, ingredients: newIngredients });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validation
    if (!recipe.name.trim()) {
      toast({
        title: 'Error',
        description: 'Recipe name is required',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!recipe.ingredients.length || !recipe.ingredients[0].ingredient_name) {
      toast({
        title: 'Error',
        description: 'At least one ingredient is required',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    onSubmit(recipe);
  };

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <VStack spacing={4} align="stretch">
        <FormControl isRequired>
          <FormLabel>Recipe Name</FormLabel>
          <Input
            value={recipe.name}
            onChange={(e) => setRecipe({ ...recipe, name: e.target.value })}
            placeholder="Enter recipe name"
          />
        </FormControl>

        <FormControl>
          <FormLabel>Ingredients</FormLabel>
          <VStack spacing={2}>
            {recipe.ingredients.map((ingredient, index) => (
              <HStack key={index}>
                <Input
                  placeholder="Ingredient name"
                  value={ingredient.ingredient_name}
                  onChange={(e) => handleIngredientChange(index, 'ingredient_name', e.target.value)}
                />
                <Input
                  placeholder="Quantity"
                  type="number"
                  value={ingredient.quantity}
                  onChange={(e) => handleIngredientChange(index, 'quantity', e.target.value)}
                  width="100px"
                />
                <Input
                  placeholder="Unit"
                  value={ingredient.unit}
                  onChange={(e) => handleIngredientChange(index, 'unit', e.target.value)}
                  width="100px"
                />
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() => removeIngredient(index)}
                  isDisabled={recipe.ingredients.length === 1}
                />
              </HStack>
            ))}
            <Button leftIcon={<AddIcon />} onClick={addIngredient} size="sm">
              Add Ingredient
            </Button>
          </VStack>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Instructions</FormLabel>
          <Textarea
            value={recipe.instructions}
            onChange={(e) => setRecipe({ ...recipe, instructions: e.target.value })}
            placeholder="Enter cooking instructions"
            rows={6}
          />
        </FormControl>

        <HStack spacing={4}>
          <Button type="submit" colorScheme="teal">
            {initialData ? 'Update Recipe' : 'Create Recipe'}
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default RecipeForm; 