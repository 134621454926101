import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  PinInput,
  PinInputField,
  HStack,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyOTP } from '../../services/api';

function OTPVerification({ phoneNumber: propPhoneNumber, setIsAuthenticated }) {
  const [otp, setOTP] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const phoneNumber = propPhoneNumber || location?.state?.phoneNumber;

  if (!phoneNumber) {
    navigate('/login');
    return null;
  }

  const handleVerify = async () => {
    setError('');
    setLoading(true);
    try {
      const response = await verifyOTP(phoneNumber, otp);
      localStorage.setItem('token', response.data.token);
      setIsAuthenticated(true);
      
      // If user exists (phone number in database), go to chat
      // If new user (just registered), go to initial setup
      if (response.data.is_new_user) {
        navigate('/initial-setup');
      } else {
        navigate('/chat');
      }
    } catch (error) {
      console.error('OTP verification failed:', error);
      setError('Invalid OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex align="center" justify="center" height="100vh" bg="gray.50">
      <Box bg="white" p={8} rounded="md" shadow="md" w="sm">
        <Heading mb={6} textAlign="center">
          Verify OTP
        </Heading>
        <Text mb={4} textAlign="center">
          Enter the OTP sent to {phoneNumber}
        </Text>
        {error && (
          <Text color="red.500" textAlign="center" mb={4}>
            {error}
          </Text>
        )}
        <HStack justify="center" mb={6}>
          <PinInput otp size="lg" value={otp} onChange={setOTP}>
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
        </HStack>
        <Button
          colorScheme="teal"
          width="full"
          onClick={handleVerify}
          isLoading={loading}
        >
          Verify OTP
        </Button>
      </Box>
    </Flex>
  );
}

export default OTPVerification; 