import React, { useState, useEffect } from 'react';
import {
  Box, Container, Heading, VStack, HStack, Text, Input, Button, IconButton,
  List, ListItem, useToast, Tabs, TabList, Tab, TabPanels, TabPanel, 
  Table, Thead, Tbody, Tr, Th, Td, ButtonGroup, Modal, ModalOverlay,
  ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure,
  Spinner, Fade
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon, AddIcon } from '@chakra-ui/icons';
import { getIngredients, updateIngredient, createIngredient, deleteIngredient, bulkCreateIngredients } from '../../services/api';
import { AudioRecorder } from 'react-audio-voice-recorder';
import { processAudio } from '../../services/api';
import ReceiptUploadModal from '../../components/RecieptUploadModal/ReceiptUploadModal';

function Inventory() {
  const [ingredients, setIngredients] = useState({
    pantry: [],
    fridge: [],
    freezer: []
  });
  const [newIngredient, setNewIngredient] = useState({ 
    name: '', 
    quantity: '', 
    unit: '',
    location: 'pantry' 
  });
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [addingItem, setAddingItem] = useState({
    name: '',
    quantity: '',
    unit: '',
    location: 'pantry'
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState('pantry');

  useEffect(() => {
    fetchIngredients();
  }, []);

  const fetchIngredients = async () => {
    try {
      const response = await getIngredients();
      // Organize ingredients by location
      const organized = {
        pantry: response.data.filter(ing => ing.location === 'pantry'),
        fridge: response.data.filter(ing => ing.location === 'fridge'),
        freezer: response.data.filter(ing => ing.location === 'freezer')
      };
      setIngredients(organized);
    } catch (error) {
      console.error('Error fetching ingredients:', error);
      toast({
        title: 'Error fetching ingredients',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUpdateQuantity = async (id, newQuantity) => {
    try {
      await updateIngredient(id, { quantity: newQuantity });
      fetchIngredients();
    } catch (error) {
      console.error('Error updating ingredient:', error);
      toast({
        title: 'Error updating ingredient',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddIngredient = async () => {
    try {
      await createIngredient(newIngredient);
      setNewIngredient({ name: '', quantity: '', unit: '', location: 'pantry' });
      fetchIngredients();
      toast({
        title: 'Ingredient added',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding ingredient:', error);
      toast({
        title: 'Error adding ingredient',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (item) => {
    setAddingItem(item);
    setIsEditing(true);
    onOpen();
  };

  const handleDelete = async (id) => {
    try {
      await deleteIngredient(id);
      fetchIngredients();
      toast({
        title: 'Ingredient deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting ingredient:', error);
      toast({
        title: 'Error deleting ingredient',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNewRecording = async (formData, location) => {
    setLoading(true);
    try {
      const parsedIngredients = await processAudio(formData);
      const newIngredients = parsedIngredients.map(ing => ({
        ...ing,
        location
      }));
      
      // Get current ingredients to check for duplicates
      const response = await getIngredients();
      const currentIngredients = response.data;
      
      for (const ingredient of newIngredients) {
        try {
          // Check if ingredient already exists
          const existingIngredient = currentIngredients.find(
            ing => ing.name.toLowerCase() === ingredient.name.toLowerCase()
          );
          
          if (existingIngredient) {
            // Update existing ingredient
            await updateIngredient(existingIngredient.id, {
              quantity: ingredient.quantity,
              unit: ingredient.unit,
              location: ingredient.location
            });
          } else {
            // Create new ingredient
            await createIngredient(ingredient);
          }
        } catch (error) {
          console.error('Error processing ingredient:', ingredient.name, error);
        }
      }
      
      // Fetch updated ingredients
      await fetchIngredients();
      
      toast({
        title: 'Ingredients processed',
        description: 'Voice recording processed successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error processing recording',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddManualItem = async () => {
    try {
      await createIngredient(addingItem);
      fetchIngredients();
      onClose();
      setAddingItem({ name: '', quantity: '', unit: '', location: 'pantry' });
      toast({
        title: 'Ingredient added',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error adding ingredient',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSaveEdit = async () => {
    try {
      await updateIngredient(addingItem.id, addingItem);
      fetchIngredients();
      onClose();
      setAddingItem({ name: '', quantity: '', unit: '', location: 'pantry' });
      setIsEditing(false);
      toast({
        title: 'Ingredient updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error updating ingredient',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleReceiptItems = async (items) => {
    try {
        const currentLocation = currentTab;
        
        // Prepare items with default location
        const processedItems = items.map(item => ({
            name: item.name,
            quantity: parseFloat(item.quantity) || 1,
            unit: item.unit || 'piece',
            location: item.location || currentLocation
        }));

        const response = await bulkCreateIngredients(processedItems);
        
        await fetchIngredients();
        toast({
            title: 'Items processed',
            description: `Successfully processed ${response.data.results.length} items`,
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    } catch (error) {
        console.error('Error processing items:', error);
        toast({
            title: 'Error processing items',
            description: error.response?.data?.error || 'Failed to process items',
            status: 'error',
            duration: 3000,
            isClosable: true,
        });
    }
  };

  const getCurrentTab = () => {
    return currentTab;
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Tabs isFitted variant="enclosed" onChange={(index) => {
        setCurrentTab(['pantry', 'fridge', 'freezer'][index]);
      }}>
        <TabList mb="1em">
          <Tab>Pantry</Tab>
          <Tab>Fridge</Tab>
          <Tab>Freezer</Tab>
        </TabList>

        <TabPanels>
          {['pantry', 'fridge', 'freezer'].map((location) => (
            <TabPanel key={location}>
              <VStack spacing={4} align="stretch">
                <Box p={4} borderWidth="1px" borderRadius="lg" bg="white">
                  <HStack justify="space-between" mb={4}>
                    <Heading size="md">{location.charAt(0).toUpperCase() + location.slice(1)} Items</Heading>
                    <HStack 
                      spacing={2} 
                      flexDirection={{ base: "column", md: "row" }}
                      align={{ base: "stretch", md: "center" }}
                      w={{ base: "full", md: "fit-content" }}
                    >
                      <Box position="relative" width={{ base: "full", md: "100px" }}>
                        <AudioRecorder
                          onRecordingComplete={(blob) => handleNewRecording(blob, location)}
                          disabled={loading}
                        />
                      </Box>
                      <Button
                        leftIcon={<AddIcon />}
                        size="sm"
                        colorScheme="teal"
                        onClick={() => {
                          setAddingItem(prev => ({ ...prev, location }));
                          onOpen();
                        }}
                        w={{ base: "full", md: "auto" }}
                      >
                        Add Item
                      </Button>
                      <Button
                        leftIcon={<AddIcon />}
                        size="sm"
                        colorScheme="teal"
                        onClick={() => setIsReceiptModalOpen(true)}
                        w={{ base: "full", md: "auto" }}
                      >
                        Upload Receipt
                      </Button>
                    </HStack>
                  </HStack>

                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Quantity</Th>
                        <Th>Unit</Th>
                        <Th>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {ingredients[location]?.map((item) => (
                        <Tr key={item.id}>
                          <Td>{item.name}</Td>
                          <Td>{item.quantity}</Td>
                          <Td>{item.unit}</Td>
                          <Td>
                            <ButtonGroup size="sm">
                              <IconButton
                                icon={<EditIcon />}
                                onClick={() => handleEdit(item)}
                                aria-label="Edit ingredient"
                              />
                              <IconButton
                                icon={<DeleteIcon />}
                                onClick={() => handleDelete(item.id)}
                                aria-label="Delete ingredient"
                              />
                            </ButtonGroup>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              </VStack>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      <Modal isOpen={isOpen} onClose={() => {
        onClose();
        setIsEditing(false);
        setAddingItem({ name: '', quantity: '', unit: '', location: 'pantry' });
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEditing ? 'Edit Item' : 'Add New Item'}</ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <Input
                placeholder="Name"
                value={addingItem.name}
                onChange={(e) => setAddingItem(prev => ({ ...prev, name: e.target.value }))}
              />
              <Input
                placeholder="Quantity"
                type="number"
                value={addingItem.quantity}
                onChange={(e) => setAddingItem(prev => ({ ...prev, quantity: e.target.value }))}
              />
              <Input
                placeholder="Unit"
                value={addingItem.unit}
                onChange={(e) => setAddingItem(prev => ({ ...prev, unit: e.target.value }))}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button 
              colorScheme="teal" 
              mr={3} 
              onClick={isEditing ? handleSaveEdit : handleAddManualItem}
            >
              {isEditing ? 'Save' : 'Add'}
            </Button>
            <Button variant="ghost" onClick={() => {
              onClose();
              setIsEditing(false);
              setAddingItem({ name: '', quantity: '', unit: '', location: 'pantry' });
            }}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ReceiptUploadModal
        isOpen={isReceiptModalOpen}
        onClose={() => setIsReceiptModalOpen(false)}
        onSubmit={handleReceiptItems}
      />
    </Container>
  );
}

export default Inventory;