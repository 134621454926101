import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Link,
  Text,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { requestOTP } from '../../services/api';

function Login({ setIsAuthenticated }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await requestOTP(username, true);
      navigate('/verify-otp', { state: { phoneNumber: username } });
    } catch (error) {
      console.error('Login failed:', error);
      setError(error.response?.data?.error || 'Failed to send OTP. Please try again.');
    }
  };

  return (
    <Flex align="center" justify="center" height="100vh" bg="gray.50">
      <Box bg="white" p={8} rounded="md" shadow="md" w="sm">
        <Heading mb={6} textAlign="center">
          Login
        </Heading>
        {error && (
          <Text color="red.500" textAlign="center" mb={4}>
            {error}
          </Text>
        )}
        <form onSubmit={handleSubmit}>
          <InputGroup mb={4}>
            <InputLeftAddon children="+1" />
            <Input
              type="tel"
              placeholder="Phone Number"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </InputGroup>
          <Button colorScheme="teal" type="submit" width="full" mb={4}>
            Login
          </Button>
        </form>
        <Text textAlign="center">
          Don't have an account?{' '}
          <Link as={RouterLink} to="/register" color="teal.500">
            Sign up
          </Link>
        </Text>
      </Box>
    </Flex>
  );
}

export default Login;